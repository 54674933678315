import ErrorView from '../components/error-view';
import Layout from '../templates/layout';

function NotFound() {
  return (
    <Layout title="お探しのページが見つかりません">
      <ErrorView
        title="404 Not Found."
        children={
          <>
            <span>お探しのページが見つかりません。</span>
            <span>URLをご確認ください。</span>
          </>
        }
        btnLabel="ホームへ"
        to="/"
      />
    </Layout>
  );
}

export default NotFound;
