const LineButton = () => (
  <a href="https://lin.ee/2mwFKq8">
    <img
      src="https://scdn.line-apps.com/n/line_add_friends/btn/ja.png"
      alt="友だち追加"
      height="36"
    />
  </a>
);

export default LineButton;
