const theme = {
  MAIN: '#f18705',
  SUB: '#00A098',
  TEXT: '#333333',
  NOTE: '#9a9a9a',
  BORDER: '#cdcdcd',
  WARNING: '#f10505',
  WHITE: '#ffffff',
  TWITTER_BLUE: '#00aced',
};

export default theme;
