import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Button from '../components/button';
import ButtonWrapper from '../components/button-wrapper';
import LineButton from '../components/line-button';
import OfficialSiteLink from '../components/official-site-link';
import { LP_SITE_URL, OFFICIAL_TWITTER_ACCOUNT } from '../constant';
import Layout from '../templates/layout';

const Title = styled.h1`
  margin: 0 0 48px;
  font-size: 24px;
  text-align: center;
`;

const Note = styled.p`
  margin: 48px 0 24px;
  text-align: center;

  span {
    display: inline-block;
  }
`;

function Home() {
  return (
    <Layout title="">
      <Title>文芸作品作成アプリ</Title>
      <ButtonWrapper>
        <Button as={Link} to="/post">
          文芸作品を作成・投稿する
        </Button>
      </ButtonWrapper>
      <Note>
        <span>あなたの投稿した作品はこちらで配信されます！</span>
        <span>是非登録してみてください。</span>
      </Note>
      <ButtonWrapper>
        <LineButton />
      </ButtonWrapper>
      <Note>
        <span>コンテスト情報などを発信しています。</span>
        <span>各サイトもチェックしてみてください！</span>
      </Note>
      <ButtonWrapper>
        <OfficialSiteLink href={LP_SITE_URL} icon="home.svg" name="home" />
        <OfficialSiteLink
          href={`https://twitter.com/${OFFICIAL_TWITTER_ACCOUNT}`}
          icon="twitter-logo.svg"
          name="twitter"
        />
      </ButtonWrapper>
    </Layout>
  );
}

export default Home;
