import styled, { css } from 'styled-components';

import { CANVAS_HEIGHT, CANVAS_WIDTH } from '../constant';

const Container = styled.div`
  margin: 40px auto;
`;

const PostDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  min-width: ${CANVAS_WIDTH}px;
  max-width: ${CANVAS_WIDTH}px;
  height: ${CANVAS_HEIGHT}px;
  padding: 16px;
  background-color: ${(p) => p.theme.WHITE};
  background-image: url('/logo-mono.svg'), url('/background.png');
  background-position: 16px 16px, top center;
  background-repeat: no-repeat;
  background-size: 60px, cover;
  border: 1.6px solid ${(p) => p.theme.MAIN};
  box-shadow: 0px 0px 3.2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  box-sizing: border-box;
  font-size: 12px;
  text-align: initial;
  white-space: pre-wrap;
  word-wrap: break-word;
`;

const cssOfFont = (props: { text: string }) => {
  const line = props.text.split('\n');
  const maxLength = line.sort((a, b) => b.length - a.length)[0].length;

  let size = 'large';
  if (line.length > 5 || maxLength > 10) {
    size = 'small';
  } else if (line.length > 3 || maxLength > 7) {
    size = 'medium';
  } else {
    size = 'large';
  }

  switch (size) {
    case 'small':
      return css`
        padding-top: 24px;
        font-size: 16px;
        line-height: 26px;
      `;
    case 'medium':
      return css`
        padding-top: 32px;
        font-size: 20px;
        letter-spacing: 0.5em;
        line-height: 34px;
      `;
    case 'large':
    default:
      return css`
        padding-top: 32px;
        font-size: 28px;
        line-height: 56px;
        letter-spacing: 0.5em;
      `;
  }
};

const PostText = styled.p<{ text: string }>`
  max-width: 100%;
  margin: 0;
  ${cssOfFont};
  writing-mode: vertical-rl;
  text-orientation: upright;
  overflow: hidden;
`;

const Name = styled.p`
  margin: 0;
  min-height: 20px;
  font-size: 16px;
  line-height: 20px;
`;

export const PostData = (props: { text: string; name: string }) => (
  <Container>
    <PostDataContainer id="post-data">
      <PostText text={props.text}>{props.text}</PostText>
      <Name>{props.name}</Name>
    </PostDataContainer>
  </Container>
);
