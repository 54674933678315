import styled from 'styled-components';

interface ButtonProps {
  color?: 'default' | 'important';
}

const Button = styled.button<ButtonProps>`
  width: max-content;
  padding: 8px 16px;
  background-color: ${(p) =>
    p.color === 'important' ? p.theme.SUB : p.theme.MAIN};
  border: none;
  border-radius: 4px;
  color: ${(p) => p.theme.WHITE};
  font-size: 14px;
  font-family: 'Noto Serif JP', serif;
  text-decoration: none;
  cursor: pointer;
`;

export default Button;
