import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import * as htmlToImage from 'html-to-image';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';
import TextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components';
import * as yup from 'yup';

import Button from '../components/button';
import ButtonWrapper from '../components/button-wrapper';
import { PostDataProps } from '../schema';
import Layout from '../templates/layout';
import { PostData } from '../components/post-data';
import { TextLink } from '../components/text-link';
import { CONTACT_URL } from '../constant';

const Attention = styled.ul`
  position: relative;
  margin: 0 auto;
  padding: 20px 0 20px 20px;

  li {
    margin-bottom: 10px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto 20px;
`;

const Label = styled.label`
  width: 80px;
  font-size: 12px;
`;

const Input = styled.input<Pick<PostDataProps, 'name' | 'hasError'>>`
  width: 100%;
  padding: 16px;
  border-radius: 4px;
  border: 1px solid ${(p) => (p.hasError ? p.theme.WARNING : p.theme.BORDER)};
  box-sizing: border-box;
  font-family: 'Noto Serif JP', serif;
  font-size: 14px;
`;

const TextArea = styled(TextareaAutosize)<
  Pick<PostDataProps, 'text' | 'hasError'>
>`
  width: 100%;
  min-height: 104px;
  padding: 16px;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid ${(p) => (p.hasError ? p.theme.WARNING : p.theme.BORDER)};
  box-sizing: border-box;
  font-family: 'Noto Serif JP', serif;
  font-size: 14px;
  outline: none;

  ::-webkit-resizer {
    display: none;
  }
`;

const Message = styled.p`
  margin: 0 0 32px;
  color: ${(p) => p.theme.WARNING};
  font-size: 12px;
`;

const Note = styled(Message)`
  color: ${(p) => p.theme.TEXT};
`;

const Preview = styled.div`
  width: max-content;
  margin: 0 auto;
`;

const schema = yup
  .object({
    name: yup.string().required('入力してください。'),
    text: yup.string().required('入力してください。'),
  })
  .required();

function Post() {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state as PostDataProps;
  const [loading, setLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>(data?.name || '');
  const [text, setText] = useState<string>(data?.text || '');

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<PostDataProps>({
    resolver: yupResolver(schema),
    defaultValues: {
      name,
      text,
    },
  });

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'text') {
        setText(value?.text || '');
      }
      if (name === 'name') {
        setName(value?.name || '');
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit = useCallback(
    async (data: PostDataProps) => {
      setLoading(true);
      const target = document.getElementById('post-data');
      if (!target) {
        setLoading(false);
        return;
      }

      try {
        // NOTE 画像が読み込まれないバグの対処法。苦肉の策
        // https://github.com/bubkoo/html-to-image/issues/361
        await htmlToImage.toPng(target);
        await htmlToImage.toPng(target);
        await htmlToImage.toPng(target);

        const dataUrl = await htmlToImage.toPng(target);
        setLoading(false);
        navigate('/confirm', { state: { ...data, src: dataUrl } });
      } catch (err) {
        console.error('oops, something went wrong!', err);
        setLoading(false);
      }
    },
    [navigate]
  );

  return (
    <Layout title="作品入力" loading={loading} loadingText="画像生成中">
      <Attention>
        <li>作品は最大19文字× 10行まで入力可能です。</li>
        <li>
          「投稿者名」にご自身のTwitterのID(@付き)を入力することで、文芸作品大賞への応募、公式LINEによる作品配信へのエントリーが可能です。（例：@hassaku1996）
        </li>
        <li>
          上記に加え、応募とエントリーには公式Twitterのフォローも必須となります。
        </li>
        <li>
          公式LINEには条件を満たした全ての作品の中からランダムに配信されます。
        </li>
      </Attention>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputWrapper>
          <Label>作品</Label>
          <TextArea
            id="post-text"
            defaultValue=""
            {...register('text')}
            text={text}
            placeholder="入力してください"
            hasError={!!errors.text}
          ></TextArea>
        </InputWrapper>
        {errors.text && (
          <ErrorMessage errors={errors} name="text" as={Message} />
        )}
        <InputWrapper>
          <Label>投稿者名</Label>
          <Input
            {...register('name')}
            placeholder="入力してください"
            hasError={!!errors.name}
          />
        </InputWrapper>
        {errors.name && (
          <ErrorMessage errors={errors} name="name" as={Message} />
        )}
        <Preview>
          <PostData text={text} name={name} />
        </Preview>
        <Note>
          プレビュー表示において作品の体裁が崩れてしまう場合は、
          <TextLink href={CONTACT_URL} target="_blank" rel="noreferrer">
            こちら
          </TextLink>
          よりお問い合わせください。
        </Note>
        <ButtonWrapper>
          <Button type="submit">確認する</Button>
        </ButtonWrapper>
      </form>
    </Layout>
  );
}

export default Post;
