import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import { ThemeProvider } from 'styled-components';

import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import theme from './styles/theme';

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('G-WRWT61KRL0');
  ReactGA.send('pageview');
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();
