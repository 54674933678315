import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Button from './button';
import ButtonWrapper from './button-wrapper';

interface ErrorProps {
  title: string;
  children: React.ReactNode;
  btnLabel: string;
  to: string;
}

const Title = styled.h2`
  margin: 48px 0 24px;
  text-align: center;
`;

const Message = styled.p`
  margin: 24px 0;
  text-align: center;

  span {
    display: inline-block;
  }
`;
const ErrorView = (props: ErrorProps) => (
  <>
    <Title>{props.title}</Title>
    <Message>{props.children}</Message>
    <ButtonWrapper>
      <Button as={Link} to={props.to}>
        {props.btnLabel}
      </Button>
    </ButtonWrapper>
  </>
);

export default ErrorView;
