import styled from 'styled-components';

interface OfficialSiteLinkProps {
  href: string;
  icon: string;
  name: string;
}

const OfficeialSite = styled.a`
  background: none;
  border: none;
  cursor: pointer;

  img {
    width: 36px;
    padding: 0 8px;
  }
`;

const OfficialSiteLink = (props: OfficialSiteLinkProps) => (
  <OfficeialSite href={props.href} target="_blank">
    <img src={props.icon} alt={props.name} />
  </OfficeialSite>
);

export default OfficialSiteLink;
