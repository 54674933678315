import React, { useEffect, useLayoutEffect } from 'react';
import styled from 'styled-components';

import Header from '../components/header';
import Loading from '../components/loading';

interface LayoutProps {
  title: string;
  children?: React.ReactNode;
  loading?: boolean;
  loadingText?: string;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: min(100vw, 640px);
  min-height: calc(100vh - 64px);
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
`;

const Layout = ({
  title,
  children,
  loading = false,
  loadingText,
}: LayoutProps) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    document.title = `${title ? title + ' | ' : ''}ブンサイ！`;
  }, [title]);

  return (
    <div>
      {loading && <Loading text={loadingText} />}
      <Header />
      <Container>{children}</Container>
    </div>
  );
};

export default Layout;
