import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Confirm from './pages/confirm';
import Done from './pages/done';
import Home from './pages/home';
import Post from './pages/post';
import NotFound from './pages/not-found';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/post" element={<Post />} />
        <Route path="/confirm" element={<Confirm />} />
        <Route path="/done" element={<Done />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
