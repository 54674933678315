import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { CONTACT_URL } from '../constant';

const Container = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 20px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  font-size: calc(10px + 2vmin);
  color: ${(p) => p.theme.WHITE};
`;

const Logo = styled.img`
  display: block;
  height: 36px;
`;

const Contact = styled.a`
  width: max-content;
  padding: 8px 16px;
  background-color: ${(p) => p.theme.MAIN};
  border: none;
  border-radius: 4px;
  color: ${(p) => p.theme.WHITE};
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
`;

const Header = () => (
  <Container>
    <Link to="/">
      <Logo src="/logo.svg" className="App-logo" alt="logo" />
    </Link>
    <Contact href={CONTACT_URL} target="_blank">
      お問い合わせ
    </Contact>
  </Container>
);

export default Header;
