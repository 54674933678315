import { useEffect } from 'react';
import ReactLoading from 'react-loading';
import styled from 'styled-components';
import theme from '../styles/theme';

interface LoadingProps {
  text?: string;
}

const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
`;

const Contents = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  padding: 20px;
  background-color: ${(p) => p.theme.WHITE};
  border-radius: 4px;
`;

const Loading = ({ text = '読込中' }: LoadingProps) => {
  useEffect(() => {
    const target = document.getElementsByTagName('body');

    if (!target[0]) {
      return;
    }

    target[0].style.overflow = 'hidden';

    return () => {
      if (!target[0]) {
        return;
      }

      target[0].style.overflow = 'visible';
    };
  }, []);

  return (
    <Container>
      <Contents>
        <ReactLoading type="spin" color={theme.MAIN} />
        <span>{`${text}...`}</span>
      </Contents>
    </Container>
  );
};

export default Loading;
