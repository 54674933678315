import { TwitterIcon, TwitterShareButton } from 'react-share';
import styled from 'styled-components';

const TwitterShare = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: max-content;
  padding: 4px 18px;
  background-color: ${(p) => p.theme.TWITTER_BLUE};
  border-radius: 4px;
  color: ${(p) => p.theme.WHITE};
  font-size: 14px;
`;

const TwitterButton = () => (
  <TwitterShareButton url={window.location.origin} hashtags={['ブンサイ']}>
    <TwitterShare>
      <TwitterIcon size={29} />
      <span>作品をシェアする</span>
    </TwitterShare>
  </TwitterShareButton>
);

export default TwitterButton;
