import { useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import Button from '../components/button';
import ButtonWrapper from '../components/button-wrapper';
import LineButton from '../components/line-button';
import OfficialSiteLink from '../components/official-site-link';
import TwitterButton from '../components/twitter-button';
import {
  CANVAS_HEIGHT,
  CANVAS_WIDTH,
  LP_SITE_URL,
  OFFICIAL_TWITTER_ACCOUNT,
} from '../constant';
import { PostDataProps } from '../schema';
import Layout from '../templates/layout';
import ErrorView from '../components/error-view';

interface UploadDataProps extends PostDataProps {
  fileName: string;
}

const Preview = styled.img`
  height: ${CANVAS_HEIGHT}px;
  width: ${CANVAS_WIDTH}px;
  margin: 40px auto;
`;

const Note = styled.p`
  margin: 20px 0;
  text-align: center;

  span {
    display: inline-block;
  }
`;

const Share = styled.div`
  position: relative;
  margin: 40px auto;
  padding: 20px 40px 0;
  border: 1px solid ${(p) => p.theme.BORDER};
  border-radius: 4px;
`;

const ShareTitle = styled(Note)`
  position: absolute;
  top: -10px;
  left: 12px;
  background-color: ${(p) => p.theme.WHITE};
  margin: 0;
  padding: 0 4px;
`;

const Procedure = styled.dl`
  margin: 0;

  dt,
  dd {
    margin: 0;
    text-align: center;
  }
`;

function Done() {
  const location = useLocation();
  const data: UploadDataProps = location.state;

  const onDownload = useCallback(() => {
    const a = document.createElement('a');
    a.href = data.src;
    a.download = data.fileName;
    a.click();
  }, [data?.fileName, data?.src]);

  return (
    <Layout title="投稿完了">
      {!data && (
        <ErrorView
          title="403 Access Denied."
          children={
            <>
              <span>投稿データがありません。</span>
              <span>作品の入力からやり直してください。</span>
            </>
          }
          btnLabel="作品を投稿する"
          to="/post"
        />
      )}
      {data && (
        <>
          <Preview src={data.src} alt="作品" />
          <Note>
            <span>投稿されました！</span>
            <span>あなたの投稿した作品はこちらで配信されます！</span>
            <span>是非登録してみてください。</span>
          </Note>
          <ButtonWrapper>
            <LineButton />
          </ButtonWrapper>
          <Share>
            <ShareTitle>作品をTwitterでシェアしませんか？</ShareTitle>
            <Procedure>
              <dt>1.作品の画像をダウンロードする。</dt>
              <dd>
                <ButtonWrapper>
                  <Button onClick={onDownload}>ダウンロード</Button>
                </ButtonWrapper>
              </dd>
              <dt>2.ダウンロードした画像をTwitterに添付して投稿しましょう。</dt>
              <dd>
                <ButtonWrapper>
                  <TwitterButton />
                </ButtonWrapper>
              </dd>
            </Procedure>
          </Share>
          <ButtonWrapper>
            <Button as={Link} to="/post">
              もっと文芸作品を作成・投稿する
            </Button>
            <Button as={Link} to="/">
              ホームへ
            </Button>
          </ButtonWrapper>
          <Note>
            <span>
              コンテスト情報などを発信しています。各サイトもチェックしてみてください！
            </span>
          </Note>
          <ButtonWrapper>
            <OfficialSiteLink href={LP_SITE_URL} icon="home.svg" name="home" />
            <OfficialSiteLink
              href={`https://twitter.com/${OFFICIAL_TWITTER_ACCOUNT}`}
              icon="twitter-logo.svg"
              name="twitter"
            />
          </ButtonWrapper>
        </>
      )}
    </Layout>
  );
}

export default Done;
