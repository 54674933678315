import axios from 'axios';
import { useCallback, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Button from '../components/button';
import ButtonWrapper from '../components/button-wrapper';
import ErrorView from '../components/error-view';
import { TextLink } from '../components/text-link';
import { CANVAS_HEIGHT, CANVAS_WIDTH, POST_URL, TERM_URL } from '../constant';
import { PostDataProps } from '../schema';
import Layout from '../templates/layout';

const Note = styled.p`
  margin: 20px 0;
  text-align: center;

  span {
    display: inline-block;
  }
`;

const Preview = styled.img`
  height: ${CANVAS_HEIGHT}px;
  width: ${CANVAS_WIDTH}px;
  margin: 40px auto;
`;

const ErrorMsg = styled.p`
  margin: 20px 0;
  color: ${(p) => p.theme.WARNING};
  text-align: center;
`;

function Confirm() {
  const [error, setError] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();
  const data: PostDataProps = location.state;

  const onPost = useCallback(async () => {
    setLoading(true);
    try {
      const headers = {
        'Content-Type': 'application/json',
      };

      const response = await axios.post(POST_URL, data, { headers });
      navigate('/done', {
        state: {
          ...data,
          fileName: response.data.fileName,
        },
      });
      setLoading(false);
    } catch (err) {
      console.log(err);
      setError('投稿できませんでした。');
      setLoading(false);
    }
  }, [data, navigate]);

  return (
    <Layout title="入力内容確認" loading={loading} loadingText="保存中">
      {!data && (
        <ErrorView
          title="403 Access Denied."
          children={
            <>
              <span>入力内容がありません。</span>
              <span>作品の入力からやり直してください。</span>
            </>
          }
          btnLabel="作品を投稿する"
          to="/post"
        />
      )}
      {data && (
        <>
          <Note>
            <TextLink href={TERM_URL} target="_blank" rel="noreferrer">
              利用規約
            </TextLink>
            に同意し、作品を投稿します。
          </Note>
          {error && <ErrorMsg>{error}</ErrorMsg>}
          {data.src && <Preview src={data.src} alt="プレビュー" />}
          <ButtonWrapper>
            <Button as={Link} to="/post" state={data}>
              いいえ
            </Button>
            <Button color="important" onClick={() => onPost()}>
              投稿する
            </Button>
          </ButtonWrapper>
        </>
      )}
    </Layout>
  );
}

export default Confirm;
